
























































































































import { Component, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import { mixins } from 'vue-class-component';
import FileUpload from '@/components/tasqsCommon/FileUpload.vue';
import { getComponent } from '@/utils/helpers';
import accountModule from '@/store/modules/accountModule';
import { getNameByEmail } from '@/utils/users';
import { SCHEDULE_ROW_HEIGHT, SCHEDULE_ROW_STACKED_HEIGHT, SCHEDULE_START_DATE_OFFSET_HOURS } from '@/lib/constants';
import tasqActionsModule from '../../store/modules/tasqActionsModule';

@Component({
  components: {
    FileUpload,
    Checklist: () => getComponent('tasqsCommon/Checklist'),
    Multiselect,
  },
})
export default class WorkTicketSidebar extends mixins() {
  reloadGanttChartKey = 0

  dates: any[] = []

  chartStartDatetime

  chartEndDateTime

  currentDate = ''

  viewTotalDays = 7

  filteredAssets: any = [
    {
      asset_name: 'testing',
      level: 'well',
      sub_rows: [
        'testing1',
        'testing2',
      ],
    },
    {
      asset_name: 'testing',
      level: 'pad',
      sub_rows: [
        'testing1',
        'testing2',
      ],
    },
    {
      asset_name: 'testing',
      level: 'pad',
      sub_rows: [
        'testing1',
        'testing2',
        'testing1',
        'testing2',
      ],
    },
    {
      asset_name: 'testing',
      level: 'pad',
      sub_rows: [
        'testing1',
        'testing2',
        'testing1',
        'testing2',
        'testing1',
        'testing2',
        'testing1',
        'testing2',
      ],
    },
  ]

  getRowColorLeft() {
    return 'background: rgba(0,0,0,0.0);';
  }

  getRowTitleWidth() {
    return 'padding-left: 36px; width: 220px; ';
  }

  scheduleColumnWidth = 155

  reloadGanttChartComponentsKey = 0

  get currentDay(): number {
    return new Date().getDate();
  }

  getDayOfWeekStringFromDate(datetime) {
    return this.getDayOfWeek(datetime.day)?.toUpperCase();
  }

  getDayOfWeek(date) {
    const dayOfWeek = new Date(date).getDay();
    return Number.isNaN(dayOfWeek) ? null
      : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayOfWeek];
  }

  calculateTotalRowHeight(row_count) {
    return ((SCHEDULE_ROW_HEIGHT - SCHEDULE_ROW_STACKED_HEIGHT) * (row_count - 1)) + SCHEDULE_ROW_HEIGHT;
  }

  created() {
    const start_datetime = new Date();
    start_datetime.setHours(0, 0, 0, 0);
    this.chartStartDatetime = start_datetime;
    this.addDataToGanttChart();
  }

  // Methods goes below

  chartStartDatetimeWithOffset() {
    const modifyDate = new Date(this.chartStartDatetime.getTime());
    return new Date(modifyDate.setHours(modifyDate.getHours() - SCHEDULE_START_DATE_OFFSET_HOURS));
  }

  async addDataToGanttChart() {
    const start_datetime = new Date(this.chartStartDatetimeWithOffset().getTime());
    const start_datetime_temp = new Date(this.chartStartDatetimeWithOffset().getTime());
    const end_datetime = new Date(start_datetime_temp.setHours(start_datetime_temp.getHours() + (this.viewTotalDays * 24)));
    end_datetime.setHours(0, 0, 0, 0);
    this.chartEndDateTime = new Date(end_datetime.getTime());

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];
    if (monthNames[this.chartStartDatetimeWithOffset().getMonth()] != monthNames[end_datetime.getMonth()]) {
      this.currentDate = `${monthNames[this.chartStartDatetimeWithOffset().getMonth()]} — ${monthNames[end_datetime.getMonth()]} ${this.chartStartDatetimeWithOffset().getFullYear()}`; // addNumberPadding((new Date().getMonth() + 1), 2, '0') + "/" + addNumberPadding((new Date().getDate()), 2, '0') + "/" + new Date().getFullYear()
    } else {
      this.currentDate = `${monthNames[this.chartStartDatetimeWithOffset().getMonth()]} ${this.chartStartDatetimeWithOffset().getFullYear()}`; // addNumberPadding((new Date().getMonth() + 1), 2, '0') + "/" + addNumberPadding((new Date().getDate()), 2, '0') + "/" + new Date().getFullYear()
    }

    // const startDateString = `${start_datetime.getUTCFullYear()}-${addNumberPadding(start_datetime.getUTCMonth() + 1, 2, '0')}-${addNumberPadding(start_datetime.getUTCDate(), 2, '0')}T${addNumberPadding(start_datetime.getUTCHours(), 2, '0')}:${addNumberPadding(start_datetime.getUTCMinutes(), 2, '0')}:${addNumberPadding(start_datetime.getUTCSeconds(), 2, '0')}`;
    // const endDateString = `${end_datetime.getUTCFullYear()}-${addNumberPadding(end_datetime.getUTCMonth() + 1, 2, '0')}-${addNumberPadding(end_datetime.getUTCDate(), 2, '0')}T${addNumberPadding(end_datetime.getUTCHours(), 2, '0')}:${addNumberPadding(end_datetime.getUTCMinutes(), 2, '0')}:${addNumberPadding(end_datetime.getUTCSeconds(), 2, '0')}`;

    // @ts-ignore

    this.dates = [];

    // Show chart 30 days
    for (let days = 0; days < this.viewTotalDays; days++) {
      let day_datetime = new Date(start_datetime);
      day_datetime = new Date(day_datetime.setDate(start_datetime.getDate() + days));
      const hours_array: Date[] = [];
      const day_object = {
        day_id: day_datetime.toString(),
        day_short: day_datetime.getDate(),
        day: day_datetime,
        hours: hours_array,
      };
      for (let hours = 0; hours < 24; hours++) {
        let hour_datetime = new Date(day_datetime);
        hour_datetime = new Date(hour_datetime.setHours(day_datetime.getHours() + hours));
        day_object.hours.push(hour_datetime);
      }
      this.dates.push(day_object);
    }
  }

  async weekArrowSelected(direction) {
    const tempStartDate = new Date(this.chartStartDatetime.getTime());
    if (direction.toLowerCase() == 'left') {
      var start_datetime = new Date(tempStartDate.setHours(tempStartDate.getHours() - (7 * 24)));
    } else {
      var start_datetime = new Date(tempStartDate.setHours(tempStartDate.getHours() + (7 * 24)));
    }

    start_datetime.setHours(0, 0, 0, 0);

    this.chartStartDatetime = start_datetime;
    this.addDataToGanttChart();
  }


  getScheduleRowHeight(row_index, total_rows) {
		return row_index == (total_rows - 1) ? SCHEDULE_ROW_HEIGHT : SCHEDULE_ROW_STACKED_HEIGHT
	}
}

